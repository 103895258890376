import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/spindle.ameba.design/spindle.ameba.design/src/components/Layout.tsx";
import SEO from '../../../components/SEO';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageTitle = makeShortcode("PageTitle");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="解剖 - アイコン" mdxType="SEO" />
    <PageTitle title="解剖" enTitle="Anatomy" mdxType="PageTitle" />
    <h2 {...{
      "id": "コンセプト",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B3%E3%83%B3%E3%82%BB%E3%83%97%E3%83%88",
        "aria-label": "コンセプト permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`コンセプト`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6c4880aa4981ff63a6622b8253f62a9a/07a9c/icon-ameba-sans.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.375%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6c4880aa4981ff63a6622b8253f62a9a/2aaaf/icon-ameba-sans.webp 160w", "/static/6c4880aa4981ff63a6622b8253f62a9a/85e47/icon-ameba-sans.webp 320w", "/static/6c4880aa4981ff63a6622b8253f62a9a/75198/icon-ameba-sans.webp 640w", "/static/6c4880aa4981ff63a6622b8253f62a9a/691bc/icon-ameba-sans.webp 960w", "/static/6c4880aa4981ff63a6622b8253f62a9a/223e5/icon-ameba-sans.webp 1280w", "/static/6c4880aa4981ff63a6622b8253f62a9a/f093e/icon-ameba-sans.webp 1440w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/6c4880aa4981ff63a6622b8253f62a9a/69538/icon-ameba-sans.png 160w", "/static/6c4880aa4981ff63a6622b8253f62a9a/72799/icon-ameba-sans.png 320w", "/static/6c4880aa4981ff63a6622b8253f62a9a/6af66/icon-ameba-sans.png 640w", "/static/6c4880aa4981ff63a6622b8253f62a9a/d9199/icon-ameba-sans.png 960w", "/static/6c4880aa4981ff63a6622b8253f62a9a/21b4d/icon-ameba-sans.png 1280w", "/static/6c4880aa4981ff63a6622b8253f62a9a/07a9c/icon-ameba-sans.png 1440w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/6c4880aa4981ff63a6622b8253f62a9a/6af66/icon-ameba-sans.png",
              "alt": "Ameba Sansを元にアイコンの意匠をおこしているイメージイラスト",
              "title": "Ameba Sansを元にアイコンの意匠をおこしているイメージイラスト",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`有機的意匠とシステム的な役割を融合させたAmebaらしいアイコンとして、ブランドタイポグラフィで使用されているAmeba Sansに着想を得て設計しています。`}</p>
    <h2 {...{
      "id": "原則",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%8E%9F%E5%89%87",
        "aria-label": "原則 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`原則`}</h2>
    <h3 {...{
      "id": "幾何学形状",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E5%B9%BE%E4%BD%95%E5%AD%A6%E5%BD%A2%E7%8A%B6",
        "aria-label": "幾何学形状 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`幾何学形状`}</h3>
    <p>{`アイコンは、原則として幾何学的な様式に倣って作り、幾何学に基づかないフリーハンドな線を使用しません。`}</p>
    <div className="Grid Grid--2cols">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-star-do.svg",
          "alt": "幾何学的な設計のアイコンの例"
        }}></img><br />{`
DO: 幾何学的な設計。`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-star-dont.svg",
          "alt": "フリーハンドによる設計のアイコンの例"
        }}></img><br />{`
DON'T: フリーハンドによる設計。`}</p>
    </div>
    <h3 {...{
      "id": "iconにしないもの",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#icon%E3%81%AB%E3%81%97%E3%81%AA%E3%81%84%E3%82%82%E3%81%AE",
        "aria-label": "iconにしないもの permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`Iconにしないもの`}</h3>
    <p>{`「Iconにしないもの」に該当する形状のものは、単独のイメージとしてSVGまたはPNG等で作成してください。`}</p>
    <ul>
      <li parentName="ul">{`正方形に収める事が難しい形状`}</li>
      <li parentName="ul">{`アイコンに2色以上の色を塗りに指定したい場合`}</li>
      <li parentName="ul">{`アイコンの中にテキスト要素が含まれている場合`}</li>
      <li parentName="ul">{`アイコンというよりイラストとして作成した、複雑かつ非幾何学的な形状のモチーフ`}</li>
      <li parentName="ul">{`Amebaサービスや、サードパーティのロゴなど`}</li>
    </ul>
    <div className="Grid Grid--3cols">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-not-colorful.svg",
          "alt": "黒い線と緑の塗りで形成されたメールアイコン"
        }}></img><br />{`
DON'T: 2色以上の色を使用している。`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-not-illust.svg",
          "alt": "アベマくんのロゴ"
        }}></img><br />{`
DON'T: ロゴそのもの、イラストに近い。`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-not-text.svg",
          "alt": "Newの文字"
        }}></img><br />{`
DON'T: テキスト表現、形状が横長。`}</p>
    </div>
    <h2 {...{
      "id": "サイズ規定",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E3%82%B5%E3%82%A4%E3%82%BA%E8%A6%8F%E5%AE%9A",
        "aria-label": "サイズ規定 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`サイズ規定`}</h2>
    <p>{`24✕24pxの範囲に収まるよう作成します。`}</p>
    <div className="Grid Grid--2cols">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-rule.png",
          "alt": "24✕24pxのアートボードサイズに収めたイメージ"
        }}></img></p>
    </div>
    <p>{`最小16✕16pxのサイズに縮小した場合、細部が潰れて読みづらくならないようにしてください。また、32pxのサイズになってもビジュアルが保たれるようにします。`}</p>
    <div className="Grid">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-16x16.svg",
          "alt": "16✕16pxサイズのメールアイコン"
        }}></img><br />{`
16✕16px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-20x20.svg",
          "alt": "20✕20pxサイズのメールアイコン"
        }}></img><br />{`
20✕20px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-24x24.svg",
          "alt": "24✕24pxサイズのメールアイコン"
        }}></img><br />{`
24✕24px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-28x28.svg",
          "alt": "28✕28pxサイズのメールアイコン"
        }}></img><br />{`
28✕28px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-size-32x32.svg",
          "alt": "32✕32pxサイズのメールアイコン"
        }}></img><br />{`
32✕32px`}</p>
    </div>
    <h3 {...{
      "id": "セーフエリア",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E3%82%BB%E3%83%BC%E3%83%95%E3%82%A8%E3%83%AA%E3%82%A2",
        "aria-label": "セーフエリア permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`セーフエリア`}</h3>
    <p>{`アイコン単体で配置する時、最低周囲48pxに要素を置かないようにします。`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/693e2d4ee2714d3bb566335a12546291/1307b/icon-safearea.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/693e2d4ee2714d3bb566335a12546291/2aaaf/icon-safearea.webp 160w", "/static/693e2d4ee2714d3bb566335a12546291/85e47/icon-safearea.webp 320w", "/static/693e2d4ee2714d3bb566335a12546291/75198/icon-safearea.webp 640w", "/static/693e2d4ee2714d3bb566335a12546291/691bc/icon-safearea.webp 960w", "/static/693e2d4ee2714d3bb566335a12546291/223e5/icon-safearea.webp 1280w", "/static/693e2d4ee2714d3bb566335a12546291/0f1ac/icon-safearea.webp 3000w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/693e2d4ee2714d3bb566335a12546291/69538/icon-safearea.png 160w", "/static/693e2d4ee2714d3bb566335a12546291/72799/icon-safearea.png 320w", "/static/693e2d4ee2714d3bb566335a12546291/6af66/icon-safearea.png 640w", "/static/693e2d4ee2714d3bb566335a12546291/d9199/icon-safearea.png 960w", "/static/693e2d4ee2714d3bb566335a12546291/21b4d/icon-safearea.png 1280w", "/static/693e2d4ee2714d3bb566335a12546291/1307b/icon-safearea.png 3000w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/693e2d4ee2714d3bb566335a12546291/6af66/icon-safearea.png",
              "alt": "アートボード上で周囲48pxに余白を設けているイメージ",
              "title": "アートボード上で周囲48pxに余白を設けているイメージ",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "形状",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#%E5%BD%A2%E7%8A%B6",
        "aria-label": "形状 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`形状`}</h2>
    <p>{`矩形の形をできるだけKeylineに合わせて作ることによって、同等のサイズ感に揃えます。`}</p>
    <div className="Grid">
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/f2b497fb5616ff75467efeee4d59e825/d9199/icon-basic-keyline.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/f2b497fb5616ff75467efeee4d59e825/2aaaf/icon-basic-keyline.webp 160w", "/static/f2b497fb5616ff75467efeee4d59e825/85e47/icon-basic-keyline.webp 320w", "/static/f2b497fb5616ff75467efeee4d59e825/75198/icon-basic-keyline.webp 640w", "/static/f2b497fb5616ff75467efeee4d59e825/691bc/icon-basic-keyline.webp 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/f2b497fb5616ff75467efeee4d59e825/69538/icon-basic-keyline.png 160w", "/static/f2b497fb5616ff75467efeee4d59e825/72799/icon-basic-keyline.png 320w", "/static/f2b497fb5616ff75467efeee4d59e825/6af66/icon-basic-keyline.png 640w", "/static/f2b497fb5616ff75467efeee4d59e825/d9199/icon-basic-keyline.png 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/f2b497fb5616ff75467efeee4d59e825/6af66/icon-basic-keyline.png",
                "alt": "キーライン",
                "title": "キーライン",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><br />{`
Keyline`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/1854ab1536ef290f37e2625bebdbf210/d9199/icon-basic-square.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1854ab1536ef290f37e2625bebdbf210/2aaaf/icon-basic-square.webp 160w", "/static/1854ab1536ef290f37e2625bebdbf210/85e47/icon-basic-square.webp 320w", "/static/1854ab1536ef290f37e2625bebdbf210/75198/icon-basic-square.webp 640w", "/static/1854ab1536ef290f37e2625bebdbf210/691bc/icon-basic-square.webp 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/1854ab1536ef290f37e2625bebdbf210/69538/icon-basic-square.png 160w", "/static/1854ab1536ef290f37e2625bebdbf210/72799/icon-basic-square.png 320w", "/static/1854ab1536ef290f37e2625bebdbf210/6af66/icon-basic-square.png 640w", "/static/1854ab1536ef290f37e2625bebdbf210/d9199/icon-basic-square.png 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/1854ab1536ef290f37e2625bebdbf210/6af66/icon-basic-square.png",
                "alt": "四角形の例",
                "title": "四角形の例",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><br />{`
Square`}<br /></p>
      {
        /* 外枠線は太さ2px、半径3pxの円弧に則る。 */
      }
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/99d326b6cf337263237ed1a2ccb4e58f/d9199/icon-basic-horizontal-rectangle.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/99d326b6cf337263237ed1a2ccb4e58f/2aaaf/icon-basic-horizontal-rectangle.webp 160w", "/static/99d326b6cf337263237ed1a2ccb4e58f/85e47/icon-basic-horizontal-rectangle.webp 320w", "/static/99d326b6cf337263237ed1a2ccb4e58f/75198/icon-basic-horizontal-rectangle.webp 640w", "/static/99d326b6cf337263237ed1a2ccb4e58f/691bc/icon-basic-horizontal-rectangle.webp 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/99d326b6cf337263237ed1a2ccb4e58f/69538/icon-basic-horizontal-rectangle.png 160w", "/static/99d326b6cf337263237ed1a2ccb4e58f/72799/icon-basic-horizontal-rectangle.png 320w", "/static/99d326b6cf337263237ed1a2ccb4e58f/6af66/icon-basic-horizontal-rectangle.png 640w", "/static/99d326b6cf337263237ed1a2ccb4e58f/d9199/icon-basic-horizontal-rectangle.png 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/99d326b6cf337263237ed1a2ccb4e58f/6af66/icon-basic-horizontal-rectangle.png",
                "alt": "横長長方形の例",
                "title": "横長長方形の例",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><br />{`
Horizontal`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/427b56cfa70895eca11bfdea7f613f05/d9199/icon-basic-vertical-rectangle.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/427b56cfa70895eca11bfdea7f613f05/2aaaf/icon-basic-vertical-rectangle.webp 160w", "/static/427b56cfa70895eca11bfdea7f613f05/85e47/icon-basic-vertical-rectangle.webp 320w", "/static/427b56cfa70895eca11bfdea7f613f05/75198/icon-basic-vertical-rectangle.webp 640w", "/static/427b56cfa70895eca11bfdea7f613f05/691bc/icon-basic-vertical-rectangle.webp 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/427b56cfa70895eca11bfdea7f613f05/69538/icon-basic-vertical-rectangle.png 160w", "/static/427b56cfa70895eca11bfdea7f613f05/72799/icon-basic-vertical-rectangle.png 320w", "/static/427b56cfa70895eca11bfdea7f613f05/6af66/icon-basic-vertical-rectangle.png 640w", "/static/427b56cfa70895eca11bfdea7f613f05/d9199/icon-basic-vertical-rectangle.png 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/427b56cfa70895eca11bfdea7f613f05/6af66/icon-basic-vertical-rectangle.png",
                "alt": "縦長長方形の例",
                "title": "縦長長方形の例",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><br />{`
Vertical`}</p>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "640px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/8ec505eccd184ffe459e064392b688c1/d9199/icon-basic-circle.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8ec505eccd184ffe459e064392b688c1/2aaaf/icon-basic-circle.webp 160w", "/static/8ec505eccd184ffe459e064392b688c1/85e47/icon-basic-circle.webp 320w", "/static/8ec505eccd184ffe459e064392b688c1/75198/icon-basic-circle.webp 640w", "/static/8ec505eccd184ffe459e064392b688c1/691bc/icon-basic-circle.webp 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8ec505eccd184ffe459e064392b688c1/69538/icon-basic-circle.png 160w", "/static/8ec505eccd184ffe459e064392b688c1/72799/icon-basic-circle.png 320w", "/static/8ec505eccd184ffe459e064392b688c1/6af66/icon-basic-circle.png 640w", "/static/8ec505eccd184ffe459e064392b688c1/d9199/icon-basic-circle.png 960w"],
                "sizes": "(max-width: 640px) 100vw, 640px",
                "type": "image/png"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/8ec505eccd184ffe459e064392b688c1/6af66/icon-basic-circle.png",
                "alt": "円形の例",
                "title": "円形の例",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><br />{`
Circle`}</p>
    </div>
    <h3 {...{
      "id": "線",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E7%B7%9A",
        "aria-label": "線 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`線`}</h3>
    <p>{`情報密度に応じて線の太さを変化させます。
特に理由がない場合は原則2pxを使用します。`}</p>
    <div className="Grid">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-line-rectangle-line.svg",
          "alt": "一本線の例"
        }}></img><br />{`
1-2 Line`}<br />{`Bold: 3px`}<br />{`Regular: 2px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-line-inner-line.svg",
          "alt": "円形の例"
        }}></img><br />{`
Circle line`}<br />{`Bold: 2.5px`}<br />{`Regular: 2px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-line-1-2-line.svg",
          "alt": "四角形の例"
        }}></img><br />{`
Rectangle Line`}<br />{`Bold: 2.5px`}<br />{`Regular: 2px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-line-circle-line.svg",
          "alt": "矩形の中に三本線がある例"
        }}></img><br />{`
Inner Line`}<br />{`Bold: 2px`}<br />{`Regular: 1.5px`}</p>
    </div>
    <h3 {...{
      "id": "角丸",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E8%A7%92%E4%B8%B8",
        "aria-label": "角丸 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`角丸`}</h3>
    <p>{`AmebaのSystem Iconは生きたコンテンツを幾何学的に表現するため、原則全ての矩形の頂点に角丸を指定します。`}</p>
    <div className="Grid">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-corner-fill.svg",
          "alt": "角丸かつFillの図解"
        }}></img><br />{`
Fill`}<br />{`Corner: 1px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-corner-stroke.svg",
          "alt": "角丸かつStrokeの図解"
        }}></img><br />{`
Stroke`}<br />{`Corner: 1px`}</p>
    </div>
    <h3 {...{
      "id": "曲線",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E6%9B%B2%E7%B7%9A",
        "aria-label": "曲線 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`曲線`}</h3>
    <p>{`曲線を描画する時は、曲率を統一するため、中央線が交差する角度毎に異なる半径の角丸を指定します。
下記は参考値です。`}</p>
    <div className="Grid">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-stroke-40deg.svg",
          "alt": "40°相当、または40°以下の図解"
        }}></img><br />{`
40°相当、または40°以下`}<br />{`Corner: 1.5px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-stroke-55deg.svg",
          "alt": "55°相当の図解"
        }}></img><br />{`
55°相当`}<br />{`Corner: 2px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-stroke-90deg.svg",
          "alt": "90°相当、または90°以下の図解"
        }}></img><br />{`
90°相当、または90°以下`}<br />{`Corner: 3px`}</p>
    </div>
    <p>{`Fillのアイコンを作成する時は、外形の角丸の値を角度毎に指定します。`}</p>
    <div className="Grid">
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-fill-40deg.svg",
          "alt": "40°相当、または40°以下の図解"
        }}></img><br />{`
40°相当、または40°以下`}<br />{`Corner: 1.5px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-fill-55deg.svg",
          "alt": "55°相当の図解"
        }}></img><br />{`
55°相当`}<br />{`Corner: 2px`}</p>
      <p><img parentName="p" {...{
          "src": "/images/styles/icon-fill-90deg.svg",
          "alt": "90°相当、または90°以下の図解"
        }}></img><br />{`
90°相当、または90°以下`}<br />{`Corner: 3px`}</p>
    </div>
    <h3 {...{
      "id": "面取り",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E9%9D%A2%E5%8F%96%E3%82%8A",
        "aria-label": "面取り permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`面取り`}</h3>
    <p>{`微細な部位で意図せず頂点が発生した場合は、半径0.5pxのCornerを指定して面取りします。
Strokeで面取りはしないでください。`}</p>
    <p><img parentName="p" {...{
        "src": "/images/styles/icon-fill-chamfer.svg",
        "alt": "面取りの図解"
      }}></img><br />{`
面取り`}<br />{`Corner: 0.5px`}</p>
    <h3 {...{
      "id": "例外",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#%E4%BE%8B%E5%A4%96",
        "aria-label": "例外 permalink",
        "className": "layout-heading-anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "width": "24",
          "height": "24",
          "viewBox": "0 0 24 24",
          "xmlns": "http://www.w3.org/2000/svg",
          "fill": "currentColor"
        }}><path parentName="svg" {...{
            "d": "M7.87 2.48c1.07 0 2.07.42 2.83 1.17l4.16 4.16c.84.84 1.27 1.96 1.27 3.05 0 .89-.29 1.77-.88 2.48-.42.51-.97.91-1.58 1.15a.998.998 0 0 1-1.3-.55c-.21-.51.04-1.1.55-1.3.31-.12.57-.32.79-.58.65-.78.53-2.03-.28-2.83L9.28 5.06c-.38-.38-.88-.58-1.41-.58-.53 0-1.04.2-1.42.58-.76.76-.76 2.07 0 2.83l1.26 1.26a.996.996 0 1 1-1.41 1.41L5.04 9.3c-1.51-1.51-1.51-4.15 0-5.66.75-.75 1.76-1.16 2.83-1.16zm8.78 11.87 1.26 1.26c.76.76.76 2.07 0 2.83a1.983 1.983 0 0 1-2.82 0l-4.17-4.17c-.77-.77-.91-1.98-.32-2.75.23-.3.51-.52.85-.65a.998.998 0 1 0-.75-1.85c-.68.26-1.26.71-1.7 1.28-1.21 1.58-.99 3.89.51 5.39l4.16 4.16c.75.76 1.76 1.17 2.83 1.17s2.07-.42 2.83-1.17c.76-.76 1.17-1.76 1.17-2.83s-.42-2.07-1.17-2.83l-1.26-1.26a.996.996 0 0 0-1.41 0c-.39.39-.4 1.03-.01 1.42z"
          }}></path></svg></a>{`例外`}</h3>
    <p>{`上記のルールに則って作成した上で、どうしても全体のバランスを取るのが難しいと感じたときは、例外的にルールを逸脱しても構いません。`}</p>
    <p>{`最終的な見た目としてAmebaのSystem Iconとしてバランスが取れていることを優先します。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      